import {first} from 'lodash';
import {createRoot} from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router';

import Client from './Client';
import Layout from './calendar/Layout';
import Events from './calendar/Events';

import './default.scss';

const root = createRoot(first(document.getElementsByTagName('body')));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Client />} />

      <Route path='/calendar' element={<Layout />}>
        <Route path=':name' element={<Events />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
