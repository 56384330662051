import {Link} from 'react-router';
import {useState, useEffect} from 'react';
import moment, {MomentInput} from 'moment';

import api from './api';
import {ApiCalendarStats, ApiDbDataType, ApiDeploymentType, ApiUserType, ApiVersionType,
  DbMessageType} from '@shared-types/api';

import './Client.scss';

const printableDate = (date: MomentInput, format?: string) => moment(date, format).format('D MMM, YYYY');

const failed = <span className="failure">Failed</span>;

type Status = {
  [key:string]: any
} | false | undefined;

const status = (data: Status, key?: string) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }

  return (key ? data[key] : data) ? 'OK' : failed;
};

const botStatus = (message: DbMessageType) => {
  if (message === undefined) {
    return 'Loading';
  } else if (!message?.date) {
    return failed;
  }

  const messageDate = printableDate(message?.date);
  const now = printableDate(new Date());
  return messageDate === now ? 'OK' : `Outdated (${messageDate})`;
};

const versionInfo = (data: ApiVersionType) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }

  const {date, version} = data;
  const d = printableDate(date, 'DD-MM-YYYY');
  return `${version} (${d})`;
};

const calendarStatus = (data: ApiCalendarStats) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }

  const {updated, records} = data;
  const date = updated ? printableDate(updated) : 'Never';
  return `${records} records (${date})`;
};

const deploymentStatus = (data: ApiDeploymentType) => {
  if (data === false) {
    return failed;
  }
  if (data === undefined) {
    return 'Loading';
  }
  const {mtime} = data;
  return mtime ? printableDate(mtime) : 'Unknown';
};

const Client = () => {
  const [username, setUsername] = useState<ApiUserType>();
  const [dbData, updateDbData] = useState<ApiDbDataType>();
  const [version, setVersion] = useState<ApiVersionType>();
  const [calendarStats, updateCalendarStats] = useState<ApiCalendarStats>();
  const [deployment, setDeployment] = useState<ApiDeploymentType>();

  useEffect(() => {
    (async () => {
      setVersion(await api<ApiVersionType>('build'));
      setDeployment(await api<ApiDeploymentType>('deployment'));
      setUsername(await api<ApiUserType>('user'));
      updateDbData(await api<ApiDbDataType>('db'));
      updateCalendarStats(await api<ApiCalendarStats>('cal/status'));
    })();
  }, []);

  return (
    <div className='client'>
      <h1>bzmn.xyz</h1>
      <ul className="statuses">
        <li>
          <b>Build</b>
          <span>{versionInfo(version)}</span>
        </li>
        <li>
          <b>Deployment</b>
          <span>{deploymentStatus(deployment)}</span>
        </li>
        <li>
          <b>API</b>
          <span>{status(username, 'username')}</span>
        </li>
        <li>
          <b>Db</b>
          <span>{status(calendarStats)}</span>
        </li>
        <li>
          <b>Bot</b>
          <span>{botStatus(dbData?.message)}</span>
        </li>
        <li>
          <b>
            <Link to='/calendar'>iCal</Link>
          </b>
          <span>{calendarStatus(calendarStats)}</span>
        </li>
      </ul>
    </div>
  );
};

export default Client;
