import {useState} from 'react';

import {useApi} from '../api';
import {map} from 'lodash';
import {NavLink} from 'react-router';

import './List.scss';

const List = () => {
  const [calendars, setCalendars] = useState([]);
  useApi('cal/list', setCalendars);
  return (
    <ul className='calendars-list'>
      {
        map(
          calendars,
          ({_id, count}) => (
            <li key={_id}>
              <NavLink to={_id}>{_id}</NavLink>
              <span>{count}</span>
            </li>
          )
        )
      }
    </ul>
  );
}

export default List;
