import {useParams} from 'react-router';
import {useApi} from '../api';
import {useState} from 'react';
import {map} from 'lodash';

import './Events.scss';

const Events = () => {
  const {name} = useParams();
  const [events, setEvents] = useState([]);
  useApi(`cal/show/${name}`, setEvents);

  return (
    <ul className='calendar-events'>
      {
        map(events, (event, index) => <li key={index}><Event {...event} /></li>)
      }
    </ul>
  );
};

export default Events;

const timeFormat = new Intl.DateTimeFormat('en-US', {timeStyle: 'short', hour12: false});
const dateFormat = new Intl.DateTimeFormat('en-US', {day: 'numeric', month: 'long'});

const getTime = (value: string, date?: boolean): string => {
  return (date ? dateFormat : timeFormat).format(Date.parse(value));
};

const Event = ({title, start, end, description, url, location}) => {
  return (
    <article className='event'>
      <header>
        <h2>{title}</h2>
        <i>
          <span>
            <time dateTime={start}>{getTime(start, true)}</time>
          </span>
          <span>
            <time dateTime={start}>{getTime(start)}</time>
            {
              end &&
                <> - <time dateTime={end}>{getTime(end)}</time></>
            }
          </span>
          <span>{location}</span>
          <span></span>
        </i>
      </header>
      <summary>{description?.plain ?? description}</summary>
      <footer>
        {
          url &&
            <a href={url}>{url}</a>
        }
      </footer>
    </article>
  );
}