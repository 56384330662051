import {Link, Outlet} from 'react-router';

import List from './List';
import {useState} from 'react';
import api from '../api';

import './Layout.scss';
import {method} from 'lodash';

const Calendar = () => {
  const [canFetch, allowFetching] = useState(true);
  const [canClear, allowCleaning] = useState(true);

  const refetch = () => {
    allowFetching(false);
    api('cal/renew').then(() => allowFetching(true));
  };

  const clear = () => {
    allowCleaning(false);
    api('cal/delete', {method: 'DELETE'}).then(() => allowCleaning(true));
  };

  return (
    <div className='calendar-layout'>
      <div className='panel'>
        <Link to='/' className='logo'>
          <h1>bzmn</h1>
        </Link>
        <h2>Calendars</h2>
        <List />
        <button onClick={refetch} disabled={!canFetch}>Refetch</button>
        <button onClick={clear} disabled={!canClear}>Erase All</button>
      </div>
      <div className='content'>
        <Outlet />
      </div>
    </div>
  );
};

export default Calendar;
